import Head from 'next/head';
import AuthGuard from '~auth/AuthGuard';
import LoginLayout from '~layouts/login/LoginLayout';
import Login from '~screens/auth/Login';
export default function LoginPage() {
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="login.tsx">
        <title> Login | Songoven Client</title>
      </Head>

      <AuthGuard data-sentry-element="AuthGuard" data-sentry-source-file="login.tsx">
        <LoginLayout data-sentry-element="LoginLayout" data-sentry-source-file="login.tsx">
          <Login data-sentry-element="Login" data-sentry-source-file="login.tsx" />
        </LoginLayout>
      </AuthGuard>
    </>;
}